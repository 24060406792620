const global = {
  api: {
    baseURL: "https://api.pettree.prolins.com.br",
    //  baseURL: "http://localhost:8080",
    // baseURL: "https://1f90-189-36-194-62.sa.ngrok.io/pettree_api_a/public",
    // baseURL: "http://192.168.1.118/pettree_api/public",
    // baseURL: "https://localteste.pagekite.me/pettree_api/public",
    // baseURL:
    //   "https://dfe8-189-36-194-62.ngrok-free.app/pettree_api_merge/public",
    // baseURL: "https://api.homologpettree2.prolins.com.br",
    // baseURL: "https://api.vettree.prolins.com.br",
    // baseURL: "http://localhost/pettree_api/public",
    baseUrlChamefacil_api: "https://chamefacilpettreeapi2.prolins.com.br/api",
    baseUrlChamefacil: "https://chamefacilpettree.prolins.com.br/api",

    // CHAMEFACIL DE TESTE
    // baseUrlChamefacil: "http://chamefacilpettreeteste.prolins.com.br/api",
    // baseUrlChamefacil_api: "http://chamefacilapiteste.prolins.com.br/api",
  },
  consultas: {
    tempoMinimo: 1,
    idTipoProcedimentoConsulta: 5,
  },
  tiposProcedimentos: {
    //consulta: 5,
    //cirurgia: 6,
    consulta: 2,
    cirurgia: 3,
    compromissoPessoal: 7,
  },
  utils: {
    diasDaSemana: [
      { value: 0, label: "Domingo" },
      { value: 1, label: "Segunda-feira" },
      { value: 2, label: "Terça-feira" },
      { value: 3, label: "Quarta-feira" },
      { value: 4, label: "Quinta-feira" },
      { value: 5, label: "Sexta-feira" },
      { value: 6, label: "Sábado" },
    ],
  },
  profiles: [
    { id: 1, description: "admin" },
    { id: 2, description: "medico" },
    { id: 3, description: "assistente" },
    { id: 4, description: "paciente" },
    { id: 56, description: "recepcao" },
  ],
  snackbar: {
    colors: {
      sucess: "#3E682A",
      error: "error",
      warning: "#FFCA28",
    },
  },
  messages: {
    internalServerError:
      "Ocorreu um erro durante o processamento da sua solicitação. Tente novamente dentro de alguns minutos. Se o serviço continuar sem funcionar, contate o administrador do sistema.",
  },
};
export { global };
